import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { IoReturnDownBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { clearAllData } from "../../../reduxFile/actions";
import store from "../../../reduxFile/store";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const VerifyLoginOTP = () => {
  const cartData = useSelector((state) => state.productData);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const [OTP, setOTP] = useState("");
  const [wrongOtpMsg, setWrongOtpMsg] = useState("");
  const [otpHit, setOtpHit] = useState(0);
  const [passError, setPassError] = useState("");
  const [inputError, setInputError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [notVerified, setNotVerified] = useState("");

  // Extract data from search params
  const data = JSON.parse(searchParams.get("Data"));
  const OTPNumber = searchParams.get("OTP");

  const handleGoToPasswordResetPage = async (e) => {
    e.preventDefault();
    setLoad(true);

    // Check if the OTP entered is valid
    if (OTP === OTPNumber) {
      //   3;
      console.log("Search Param Data: ", data.data);

      await handleLogin(data.data);
    } else {
      setWrongOtpMsg("Invalid OTP");
      setOtpHit((prev) => prev + 1);
      if (otpHit >= 2) {
        navigate({
          pathname: "/login",
          search: createSearchParams({ tooManyHit: "YES" }).toString(),
        });
      }
    }
    setLoad(false);
  };

  const storeCart = async () => {
    const add_all = cartData.map((item) => ({
      product_id: item.product_id,
      item_qty: item.item_qty,
      item_price: item.item_price,
      total_price: item.total_price,
      variation_id: item.variation_id,
      c_id: localStorage.getItem("customer_id"),
    }));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ add_all }),
    };

    try {
      const response = await fetch(
        "https://azamdeal.com/apk_api/azamDeals/public/api/storeCart",
        requestOptions
      );
      const result = await response.json();
      if (result.status === 200) {
        store.dispatch(clearAllData());
      }
    } catch (error) {
      console.error("Error storing cart:", error);
    }
  };

  const handleLogin = async (data) => {
    setLoad(true);
    setInputError("");
    setEmailError("");

    // Prepare the body data
    const raw = {
      email: data.email,
      id: data.id,
    };
    console.log("Body Data:", JSON.stringify(raw));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}verifyAndLogin`,
        requestOptions
      );
      const result = await response.json();

      if (result.status === 200) {
        localStorage.setItem("customer_id", result.Customer_id);
        localStorage.setItem("customerName", result.data.name);
        localStorage.setItem("Token", result.token);
        localStorage.setItem("isLogin", "Yes");

        if (cartData.length > 0) {
          await storeCart();
        }
        navigate("/");
      } else {
        handleLoginError(result);
      }
    } catch (error) {
      console.error("Login error:", error);
      setPassError("An error occurred. Please try again.");
    } finally {
      setLoad(false);
    }
  };

  const handleLoginError = (result) => {
    if (result.status === 401) {
      setPassError(result.message);
    } else if (result.status === 404) {
      setEmailError(`${result.message}, Please Register`);
    } else if (result.status === 403) {
      setNotVerified(result.message);
    }
  };

  return (
    <div className="relative h-screen">
      <video
        autoPlay
        muted
        loop
        className="absolute top-0 left-0 w-full h-full object-cover"
      >
        <source src="/login.mp4" type="video/mp4" />
      </video>
      <div>
        <section className="relative bg-[rgba(0,0,0,0.3)] min-h-screen flex items-center justify-center">
          <div className="bg-[rgba(0,0,0,0.5)] h-screen md:h-auto lg:h-auto w-screen flex rounded-2xl shadow-lg max-w-3xl items-center p-5 md:p-0 lg:p-0">
            <div className="w-full md:w-1/2 px-8 md:px-12">
              <h2 className="font-bold text-2xl text-[#fff]">Enter OTP</h2>
              <p className="text-xs mt-4 text-[#fff] capitalize">
                An OTP has been sent to your device. Kindly check your inbox and
                enter the OTP.
              </p>
              <form className="flex flex-col gap-4 mt-10">
                <div className="relative mt-20 md:mt-0 lg:mt-0">
                  <label
                    htmlFor="otp"
                    className="text-sm font-bold text-[#fff] mb-4 ml-1"
                  >
                    Enter OTP:
                  </label>
                  <input
                    id="otp"
                    className="p-2 mt-2 rounded-xl border w-full focus:outline-emerald-500"
                    type="number"
                    value={OTP}
                    placeholder="OTP"
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  {wrongOtpMsg && (
                    <div className="text-xs ml-2 text-red-500 font-semibold mt-1">
                      {wrongOtpMsg}
                    </div>
                  )}
                  {inputError && (
                    <div className="text-xs mt-3 border p-3 rounded-md bg-red-50 border-red-500 text-red-500">
                      {inputError}
                    </div>
                  )}
                  {emailError && (
                    <div className="text-xs mt-3 border p-2 rounded-md bg-red-50 border-red-500 text-red-500">
                      {emailError}
                    </div>
                  )}
                  {passError && (
                    <div className="text-xs mt-3 border p-3 rounded-md bg-red-50 border-red-500 text-red-500">
                      {passError}
                    </div>
                  )}
                  {notVerified && (
                    <div className="text-xs mt-3 border p-3 rounded-md bg-red-50 border-red-500 text-red-500">
                      {notVerified}
                    </div>
                  )}
                </div>
                <button
                  onClick={handleGoToPasswordResetPage}
                  className="bg-emerald-500 rounded-xl text-white py-2 hover:scale-105 duration-300"
                >
                  Next
                </button>
              </form>
              <button
                className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#008000]"
                onClick={() => navigate(-1)}
              >
                <IoReturnDownBack className="mr-4" />
                Cancel
              </button>
              <div className="mt-72 md:mt-20 lg:mt-20 text-xs flex justify-between items-center text-[#008000]">
                <p className="text-white">Create a new account?</p>
                <button
                  className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
                  onClick={() => navigate("/register")}
                >
                  Register
                </button>
              </div>
              <hr className="mt-2 border border-gray-400" />
            </div>
            <div className="hidden md:block lg:block w-1/2 p-2">
              <img
                className="rounded-2xl"
                src="/login-pic.jpeg"
                alt="Login Illustration"
              />
            </div>
          </div>
        </section>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={load}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </div>
  );
};

export default VerifyLoginOTP;
