const initialState = {
  count: 0, // This is just a placeholder, replace it with your actual initial state structure.
  productData: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return { ...state, count: state.count + 1 };

    case 'DECREMENT':
      return { ...state, count: state.count - 1 };

    case 'SET_PRODUCT_DATA':
      const newState = { ...state, productData: [...state.productData, action.payload] };
      console.log('New State with updated productData:', newState);
      return newState;

    case 'REMOVE_PRODUCT':
      const updatedProductData = state.productData.filter(item => item.variation_id !== action.payload);
      return { ...state, productData: updatedProductData };

    case 'CLEAR_ALL_DATA':
      return initialState;
      
    default:
      return state;
  }
};

export default rootReducer;