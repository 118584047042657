import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { MdRemoveShoppingCart } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { Link } from "react-router-dom";
import { UseSelector, useSelector } from "react-redux";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeProduct } from "../../reduxFile/actions";

const UnlogCart = () => {

  const dispatch = useDispatch();


  const removeItem = async (id) => {
    console.log("var id :", id);
    // Variation ID of the product you want to remove
    const variationIdToRemove = id;

    // Dispatch the removeProduct action with the variationId as payload
    dispatch(removeProduct(variationIdToRemove));
  }

  const Navigate = useNavigate();

  const [cartItem, setCartItem] = useState([]);
  const [totalItem, setTotalItem] = useState();
  const [totalPrice, setTotalPrices] = useState();
  const [itemPrice, setItemPrice] = useState();

  // Load
  const [load, setLoad] = useState(true);

  const productData = useSelector((state) => state.productData)
  console.log("cart data :", productData);
  

  const ItemPrice = productData.reduce((accumulator, el) => {
    return accumulator + parseInt(el.item_price);
  }, 0);
  const TotalPrice = productData.reduce((accumulator, el) => {
    return accumulator + parseInt(el.total_price);
  }, 0);
  const TotalItemFromState = productData.reduce((accumulator, el) => {
    return accumulator + parseInt(el.item_qty);
  }, 0);

  const handleGoAddress = () => {
    setLoad(true);
    Navigate("/login");
  };

  const handleNavigateProductDetail = (el) => {
    localStorage.setItem("Product ID", el.product_id);
    Navigate("/Product");
  };



  useEffect(() => {
    setCartItem(productData);
  }, [])

  useEffect(() => {
    setTotalPrices(TotalPrice);
    setTotalItem(TotalItemFromState);
    setItemPrice(ItemPrice)
  }, [productData]);

  return (
    <>
      {/* {load ? (
        <div className="h-screen bg-white"></div>
      ) : ( */}
      <Layout>

        <div className="flex flex-col md:flex-col lg:flex-row p-1 md:p-10 lg:p-10">
          <div className="bg-white rounded-2xl w-full mb-5">
            <div className="p-10 font-bold text-3xl">Shopping Cart</div>
            <hr className="border-[1px] mx-5 border-[#008000]" />
            {productData && productData.length > 0 ? (
              productData.map((el, index) => {
                return (
                  <div key={index}>
                    <div className="flex p-1 pb-5">
                      <div
                        className="ml-2 md:ml-5 lg:ml-5 mt-7 md:mt-4 lg:mt-4"
                        onClick={() => handleNavigateProductDetail(el)}
                      >
                        <img
                          src={
                            process.env.REACT_APP_MEDIA_BASE_URL +
                            el.product_base_image
                          }
                          alt="azamDeal"
                          className="object-scale-down h-[190px] w-[150px] md:h-[185px] lg:h-[170px] rounded"
                        />
                      </div>

                      {/* Second Flex */}
                      <div className="w-2/3  p-5">
                        <div className="font-bold text-lg">
                          <p>{el.product_name}</p>
                        </div>
                        <div className="font-semibold text-sm">
                          <p>{el.product_desc.slice(0, 100)}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center font-bold text-sm mt-3">
                            <p className="mr-2 hidden md:block lg:block">
                              Qty:{" "}
                            </p>
                            <div className="flex items-center border text-[#008000] border-emerald-200 rounded-md p-1">
                            <button
                                    onClick={() => {setTotalItem(totalItem - 1); setTotalPrices(totalPrice - itemPrice)}}
                                    disabled={totalItem < 2}
                                  >
                                    <AiOutlineMinus className="text-lg md:text-2xl lg:text-2xl font-semibold mx-2" />
                                  </button>
                              <span className="text-center text-black px-5 border-t-white border-b-white border-x-[#008000]">
                                {/* {parseInt(el.item_qty)} */}
                                {totalItem}
                              </span>
                              <button 
                              onClick={() => {setTotalItem(totalItem + 1); setTotalPrices(totalPrice + itemPrice)}}
                              >
                                    <AiOutlinePlus className="text-lg md:text-2xl lg:text-2xl font-semibold mx-2" />
                                  </button>
                            </div>
                          </div>
                          <div className="font-bold text-sm block md:hidden lg:hidden mt-2">
                            <p>
                              <span className="text-[#008000] text-xl">
                                ₹{el.item_price}
                                .00
                              </span>
                            </p>
                          </div>
                        </div>
                        <div>
                          <button
                            className="p-2 w-full bg-red-500 md:bg-white lg:bg-white md:text-red-500 lg:text-red-500 outline md:outline-red-500 lg:outline-red-500 text-white font-semibold mt-2 rounded-md"
                            onClick={() => removeItem(el.variation_id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                      {/* Third Flex */}
                      <div className="justify-end hidden md:block lg:block font-bold text-sm mt-2">
                        <p>
                          <span className="text-[#008000] text-xl">
                            ₹{el.item_price}.00
                          </span>
                        </p>
                      </div>
                    </div>
                    <hr className="border-[0.5px] border-gray-200 mx-5" />
                  </div>
                );
              })
            ) : (
              <div className="bg-white p-5 rounded-2xl w-full md:w-2/3 lg:w-2/3">
                <div className="flex justify-around items-center">
                  <div>
                    <MdRemoveShoppingCart className="text-9xl text-gray-500 " />
                  </div>
                  <div>
                    <p className="text-lg font-semibold text-gray-700">
                      <span className="font-bold">
                        No..! Items Available
                      </span>
                      <br /> Please Add items to carts...!
                    </p>
                    <div className="flex mt-5 justify-center"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="lg:block sticky top-28 p-5 md:p-10 bg-white ml-5 md:ml-10 h-64 rounded-2xl shadow-xl">
            <BsFillCheckCircleFill className="text-sm text-[#008000]" />
            <span className="text-center text-sm text-[#008000]">
              Orders Above 499/- are eligible for free Delivery
            </span>
            <p className="text-xl font-semibold mt-2">
              SubTotal:{" "}
              <span>
                ({totalItem} Items):{" "}
                <span className="font-bold text-[#008000]">
                  {"₹" + totalPrice + "/-"}
                </span>
              </span>
            </p>
            <div className="flex items-center mt-2">
              <input className="mt-1 mr-2" type="checkbox" name="" id="" />
              {/* <span className="font-semibold text-sm">
                This Order Contains a Gift.
              </span> */}
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="w-full p-2 bg-[#008000] hover:bg-green-600 text-white rounded-md font-semibold"
                onClick={handleGoAddress}
                disabled={productData.length === 0}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>

        </div>

      </Layout>
      {/* )} */}
    </>
  );
};

export default UnlogCart;
