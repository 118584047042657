import React, { useEffect, useRef, useState } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { FiBell } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { LinkedinIcon } from "react-share";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import _ from "lodash"; // Import lodash for debouncing
import imageCompression from "browser-image-compression";

const fetchImageAsBlob = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return blob;
};

const compressBlobImage = async (blob) => {
  const options = {
    maxSizeMB: 1, 
    maxWidthOrHeight: 1920, 
    useWebWorker: true, 
  };
  const compressedFile = await imageCompression(blob, options);
  return compressedFile;
};

const Navbar = (props) => {
  const [isLogin, setIsLogin] = useState();
  const [cartItem, setCartItem] = useState();

  const [logoCompressedImage,setLogoCompressedImage] = useState();
  const [navbgCompressedImage,setNavbgCompressedImage] = useState();


  // Loader Hooks
  const [load, setLoad] = useState(false);
  const [sLoading, setSLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false); // Control suggestions visibility
  const searchBarRef = useRef(null); // Create a ref for search bar and suggestions

  useEffect(() => {
    setIsLogin(localStorage.getItem("isLogin"));
  }, []);

  // Total Cart Amount
  const getTotalCartItems = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `countCartItems/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setCartItem(result.data);
      })
      .catch(
        (error) => {}
        // console.log("error", error)
      );
  };

  useEffect(() => {
    getTotalCartItems();
    if (isLogin === null) {
      setSLoading(false);
    }
  }, [props.CartItem]);

  let Navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const debouncedFetchSuggestions = _.debounce(async (query) => {
    if (query.length > 1) {
      setSLoading(true);
      try {
        const response = await fetch(
          `https://azamdeal.com/apk_api/azamDeals/public/index.php/api/searchSuggestions/${query}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log("Suggestions fetched:", result.data); // Log the suggestions
        // setSuggestions(result.data.slice(0, 3));
        setSuggestions(result.data);
        if (result.state === 200) {
          setSuggestions(result.data);
          // setSuggestions(result.data.slice(0, 5));
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      } finally {
        setSLoading(false);
      }
    } else {
      setSuggestions([]);
    }
  }, 100);

  useEffect(() => {
    debouncedFetchSuggestions(searchText);
    console.log("Fetching suggestions", JSON.stringify(suggestions, null, 2));
  }, [searchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSuggestions(false); // Hide suggestions when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);

  // Handle actual search when user clicks on search   or suggestion
  const handleSearch = (e, selectedSuggestion) => {
    e.preventDefault();
    setSLoading(true);
    const query = selectedSuggestion || searchText;

    Navigate({
      pathname: "/search",
      search: createSearchParams({
        searchText: query,
      }).toString(),
    });

    setSLoading(false);
  };

  const productData = useSelector((state) => state.productData);
  console.log("data length :", productData.length);

  useEffect(() => {
    let blobUrl = null;
    const logoImageCompressor = async (imagePath) => {
      const blob = await fetchImageAsBlob(imagePath);
      const compressedFile = await compressBlobImage(blob);
      blobUrl = URL.createObjectURL(compressedFile);
      setLogoCompressedImage(blobUrl);
    }
    logoImageCompressor('/azamlogo.jpg');
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [])

  useEffect(() => {
    let blobUrl = null;
    const navbgImageCompressor = async (imagePath) => {
      const blob = await fetchImageAsBlob(imagePath);
      const compressedFile = await compressBlobImage(blob);
      blobUrl = URL.createObjectURL(compressedFile);
      setNavbgCompressedImage(blobUrl);
    }
    navbgImageCompressor('/navbg.jpg');
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [])

  return (
    <div>
      <div
        className="sticky top-0 z-20 rounded-b-lg md:rounded-b-none lg:rounded-b"
        style={{
          backgroundImage: `url(${navbgCompressedImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="block md:block lg:hidden">
          <div className="flex items-center justify-between">
            <div
              className="p-2 ml-2 cursor-pointer"
              onClick={() => Navigate("/")}
            >
              <img
                width={150}
                height={40}
                src={logoCompressedImage}
                alt="logo"
                className="mt-2 rounded-md"
              />
            </div>
            <div>
              <div className="mr-2">
                {isLogin === "Yes" ? (
                  <h1 className="text-white font-bold font-serif capitalize">
                    <span className="font-semibold font-sans mr-[2px]">
                      Welcome,{" "}
                    </span>
                    {localStorage.getItem("customerName").slice(0, 10) + ".."}
                  </h1>
                ) : (
                  <p className="text-white font-bold">Welcome to AZAMDEAL</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto px-3 sm:px-10">
          <div className="top-bar h-16 lg:h-auto flex items-center justify-between py-4 mx-auto">
            <Link to="/">
              <div
                className="mr-3 lg:mr-12 xl:mr-12 hidden md:hidden lg:block cursor-pointer"
                onClick={() => Navigate("/")}
              >
                <img width={150} height={40} src="/azamlogo.jpg" alt="logo" />
              </div>
            </Link>
            <div className="w-full transition-all duration-200 ease-in-out lg:flex lg:max-w-[520px] xl:max-w-[750px] 2xl:max-w-[900px] md:mx-12 lg:mx-4 xl:mx-0">
              <div className="w-full flex flex-col justify-center flex-shrink-0 relative z-30">
                <div className="flex flex-col mx-auto w-full">
                  <form className="relative pr-12 md:pr-14 bg-white shadow-sm rounded-md w-full">
                    <div className="flex flex-col relative">
                      <input
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          setShowSuggestions(true); // Show suggestions when typing
                        }}
                        className="form-input w-full pl-5 appearance-none transition ease-in-out border text-input text-sm font-sans rounded-md min-h-10 h-10 duration-200 bg-white focus:ring-0 outline-none border-none focus:outline-none placeholder-gray-500 placeholder-opacity-75"
                        placeholder="Search for herbs (e.g Kadhaa)"
                      />
                      {searchText && (
                        <button
                          type="button"
                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-400 hover:text-red-600"
                          onClick={() => {
                            setSearchText(""); // Clear search input
                            setShowSuggestions(false); // Hide suggestions
                          }}
                        >
                          <RxCross2 size={20} />
                        </button>
                      )}
                      {/* Suggestions Dropdown */}
                      {showSuggestions && suggestions.length > 0 && (
                        <div className="absolute z-40 mt-9 w-full bg-white shadow-lg">
                          <ul className="overflow-x-auto">
                            {suggestions.map((el, index) => (
                              <li
                                className="group overflow-hidden flex p-4 bg-white relative mb-4 cursor-pointer"
                                key={index}
                                onClick={() => {
                                  const formattedProductName = el.product_name
                                    .replace(/[\s\/]+/g, "-")
                                    .replace(/--+/g, "-")
                                    .replace(/[^a-zA-Z0-9-]/g, "");

                                  Navigate(`/Product/${formattedProductName}`, {
                                    state: { id: el.id, name: el.product_name },
                                  });
                                  setShowSuggestions(false); // Hide suggestions after click
                                }}
                              >
                                {/* Product Image */}
                                <div className="relative flex justify-center cursor-pointer">
                                  <img
                                    src={
                                      process.env.REACT_APP_MEDIA_BASE_URL +
                                      el.product_base_image
                                    }
                                    alt={el.product_name}
                                    className="object-scale-down transition duration-150 ease-linear transform group-hover:scale-105 h-16 w-16"
                                  />
                                </div>

                                {/* Product Info */}
                                <div className="w-full px-2 lg:px-4 pb-3 overflow-hidden">
                                  <div className="relative mb-1">
                                    <h2 className="text-heading mb-0 block text-sm font-medium text-gray-600">
                                      <span className="line-clamp-2">
                                        {el.product_name}
                                      </span>
                                    </h2>
                                  </div>
                                  <div className="flex font-bold justify-between items-center text-heading text-sm sm:text-base space-s-2 md:text-base lg:text-xl">
                                    <div className="flex items-center gap-2">
                                      <strike className="font-bold text-xs text-gray-400">
                                        {"₹" + el.variation_mrp}
                                      </strike>
                                      <p className="text-[#008000] text-sm font-bold">
                                        {"₹" + el.variation_base_price}/-
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={handleSearch}
                      aria-label="Search"
                      type="submit"
                      disabled = {searchText === ''}
                      className="outline-none text-xl text-gray-400 absolute top-0 right-0 end-0 w-12 md:w-14 h-full flex items-center justify-center transition duration-200 ease-in-out hover:text-heading focus:outline-none"
                    >
                      <BiSearch />
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="hidden md:hidden md:items-center lg:flex xl:block absolute inset-y-0 right-0 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                className="pr-5 text-white text-2xl font-bold"
                aria-label="Alert"
              >
                <FiBell className="w-6 h-6 drop-shadow-xl" />
              </button>
              {isLogin === "Yes" ? (
                <Link to="/cart-page">
                  <button
                    aria-label="Total"
                    onClick={() => setLoad(true)}
                    className="relative px-5 text-white text-2xl font-bold"
                  >
                    {cartItem > 0 ? (
                      <span className="absolute z-10 top-0 right-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-medium leading-none text-red-100 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                        {cartItem}
                      </span>
                    ) : (
                      <span className="absolute z-10 top-0 right-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-medium leading-none text-transparent transform -translate-x-1/2 -translate-y-1/2 bg-transparent rounded-full"></span>
                    )}
                    <FiShoppingCart className="w-6 h-6 drop-shadow-xl" />
                  </button>
                </Link>
              ) : (
                <Link to="/login">
                  <button
                    aria-label="Total"
                    className="relative px-5 text-white text-2xl font-bold"
                  >
                    {productData.length > 0 ? (
                      <span className="absolute z-10 top-0 right-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-medium leading-none text-red-100 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                        {productData.length}
                      </span>
                    ) : (
                      <span className="absolute z-10 top-0 right-0 inline-flex items-center justify-center p-1 h-5 w-5 text-xs font-medium leading-none text-transparent transform -translate-x-1/2 -translate-y-1/2 bg-transparent rounded-full"></span>
                    )}
                    <FiShoppingCart className="w-6 h-6 drop-shadow-xl" />
                  </button>
                </Link>
              )}
              <button
                className="pl-5 text-white text-3xl font-bold"
                aria-label="Login"
              >
                <div>
                  {isLogin === "Yes" ? (
                    <Link to="/dashboard" className="capitalize">
                      {localStorage.getItem("customerName").slice(0, 1)}
                    </Link>
                  ) : (
                    <Link to="/login">
                      <FiUser className="w-6 h-6 drop-shadow-xl" />
                    </Link>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Navbar;
