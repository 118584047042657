import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight, FiMinus, FiPlus } from "react-icons/fi";
import Dashboard from "./Dashboard";
import Layout from "../../Layout/Layout";
import Review from "../Products/Review";
import YMAL from "../Products/YMAL";
import RelatedProduct from "../Products/RelatedProduct";
import {
  Backdrop,
  Box,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";
import ReactImageMagnify from "react-image-magnify";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ProductSideCard from "../Products/ProductSideCard";
import { result } from "lodash";

const OrderDetails = () => {
  const { id } = useParams();
  const [load, setLoad] = useState(true);
  const [apiLoader, setApiLoader] = useState(true);
  const [getProductData, setGetProductData] = useState("");

  const [changeImage, setchangeImage] = useState();

  const selectImage = (el, i) => {
    setchangeImage(el);
  };

  const getOrderDetails = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://azamdeal.com/apk_api/azamDeals/public/api/OrderDetailsbyProductID/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Order Data :", result.data);
        if (result[0].status === 200) {
          setGetProductData(result[0].data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleReltaedSelect = (id) => {
    getOrderDetails();
    const element = document.getElementById("onTop");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Variartion
  const [selectedVariations, setSelectedVariations] = useState([]);
  const handleGetByVariation = async (id) => {
    // setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    // console.log(id);
    await fetch(
      process.env.REACT_APP_BASE_URL + `getdataByV_id/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setSelectedVariations(result.variation[0]);
      })
      .catch((error) => {
        // console.log("error", error)
      });
    setLoad(false);
  };

  useEffect(() => {
    getOrderDetails();
    // console.log("Product Data :", result.data , id);
  }, []);

  function FacebookCircularProgress(props) {
    return (
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "#008000" : "#308fe8",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  return (
    <>
      <Layout>
        {/* {apiLoader && (
                    <div className="h-screen bg-white">
                        <Box
                            sx={{ flexGrow: 1 }}
                            className="flex justify-center items-center pt-64"
                        >
                            <FacebookCircularProgress />
                        </Box>
                    </div>
                )} */}
        {/* {console.log("product_name :", getProductData.product_name)} */}

        {apiLoader === false ? (
          <div className="h-screen bg-white" />
        ) : (
          <div className="px-0 py-10 lg:py-10 font-sans" id="onTop">
            <div className="mx-auto px-3 lg:px-10 max-w-screen-2xl">
              <div className="flex items-center pb-4">
                <ol className="flex items-center w-full overflow-hidden font-serif">
                  <li className="text-sm pr-1 transition duration-200 ease-in cursor-pointer hover:text-emerald-500 font-semibold">
                    <Link to="/">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li className="text-sm mt-[1px]">
                    {" "}
                    <FiChevronRight />{" "}
                  </li>
                  <li className="text-sm pl-1 transition duration-200 ease-in cursor-pointer hover:text-emerald-500 font-semibold ">
                    <Link to="/recent-orders">
                      {/* <a>{product.children}</a> */}Order
                    </Link>
                  </li>
                  <li className="text-sm mt-[1px]">
                    {" "}
                    <FiChevronRight />{" "}
                  </li>
                  <li className="text-sm px-1 transition duration-200 ease-in ">
                    {getProductData && getProductData.product_name}
                  </li>
                </ol>
              </div>
              <div className="w-full rounded-lg p-3 lg:p-12 bg-white">
                <h1 className="block md:block lg:hidden leading-7 text-xl mb-5 md:text-xl lg:text-2xl font-semibold font-serif text-gray-800">
                  {getProductData && getProductData.product_name},{" "}
                  {selectedVariations
                    ? selectedVariations.variation_weight
                    : null}
                </h1>
                <div className="flex flex-col xl:flex-row">
                  <div className="flex-shrink-0 xl:pr-10 lg:block w-full mx-auto md:w-6/12 lg:w-5/12 xl:w-4/12">
                    {/* <Discount product={product} slug={true} /> */}
                    <div className="h-[500px] w-full">
                      <ReactImageMagnify
                        className=""
                        {...{
                          smallImage: {
                            // alt: "Wristwatch by Ted Baker London",
                            isFluidWidth: true,
                            src:
                              process.env.REACT_APP_MEDIA_BASE_URL +
                              (changeImage ||
                                getProductData.product_base_image),
                            // sizes:
                            //   "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px",
                          },
                          largeImage: {
                            src:
                              process.env.REACT_APP_MEDIA_BASE_URL +
                              (changeImage ||
                                getProductData.product_base_image),
                            width: 1200,
                            height: 1800,
                          },
                          enlargedImagePosition: "beside",
                          enlargedImageContainerDimensions: {
                            width: "400%",
                            height: "100%",
                          },
                          enlargedImagePositionRelativeToContainer: true, // add this prop
                        }}
                      />
                    </div>
                    <div className="ml-0 md:ml-0 sm:mt-10 lg:ml-0 flex justify-center">
                      <div className="flex gap-2 justify-center items-center">
                        {getProductData.productimages &&
                          getProductData.productimages.map((el, index) => {
                            return (
                              <div
                                key={index}
                                onMouseOver={() => selectImage(el, index)}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_MEDIA_BASE_URL + el
                                  }
                                  alt=""
                                  className="border w-20 h-24 object-scale-down"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-10 md:mt-10 lg:mt-10">
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row">
                      <div className="w-full md:w-7/12 md:pr-4 lg:pr-4 xl:pr-12">
                        <div className="mb-6">
                          <h1 className="hidden md:block lg:block leading-7 text-lg md:text-xl lg:text-2xl mb-1 font-semibold font-serif text-gray-800">
                            {getProductData && getProductData.product_name},{" "}
                            {getProductData && getProductData.variation_weight}
                          </h1>
                          <div className="uppercase font-serif font-medium text-gray-500 text-sm">
                            {getProductData.in_stock === 1 ? (
                              <button
                                disabled
                                className="font-sans text-xs bg-emerald-100 text-gray-700 p-1 rounded-md"
                              >
                                Instock
                              </button>
                            ) : (
                              <button
                                disabled
                                className="font-sans text-xs bg-red-100 text-red-700 p-1 rounded-md"
                              >
                                Out of Stock
                              </button>
                            )}
                          </div>
                        </div>
                        {/* <Price product={product} /> */}
                        <strike className="product-price font-bold text-md text-gray-400">
                          {getProductData
                            ? "₹" + getProductData.maximum_retail_price + "/-"
                            : "₹" + getProductData.maximum_retail_price + "/-"}
                        </strike>
                        <div className="product-price font-bold text-3xl text-[#16b216]">
                          {getProductData
                            ? "₹" + getProductData.total_items_price + "/-"
                            : "₹" + getProductData.discounted_price + "/-"}
                        </div>
                        <div>
                          <p className="text-sm leading-6 text-gray-500 md:leading-7">
                            {getProductData && getProductData.product_desc}
                          </p>
                          <div className="flex flex-col mt-4">
                            <span className="font-serif font-semibold py-1 text-sm d-block">
                              <span className="text-gray-700">Status:</span>
                              <span className="bg-emerald-100 p-[5px] ml-2 rounded-md">
                                {getProductData && getProductData.orderStatus}
                              </span>
                            </span>
                          </div>
                          <div className="flex flex-col mt-4">
                            <span className="font-serif font-semibold py-1 text-sm d-block">
                              <span className="text-gray-700">qty:</span>
                              <span className="bg-emerald-100 p-[5px] ml-2 rounded-md">
                                {getProductData && getProductData.item_qty}
                              </span>
                            </span>
                          </div>
                          <div className="mt-8">
                            <h3 className="text-base font-semibold mb-1 font-serif">
                              Share this product on your social network
                            </h3>
                            <p className="font-sans text-sm text-gray-500">
                              For get lots of traffic from social network share
                              this product
                            </p>
                            <ul className="flex mt-4">
                              <li className="flex items-center text-center border border-gray-100 rounded-full hover:bg-emerald-500  mr-2 transition ease-in-out duration-500">
                                <FacebookShareButton url={window.location.href}>
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                              </li>
                              <li className="flex items-center text-center border border-gray-100 rounded-full hover:bg-emerald-500  mr-2 transition ease-in-out duration-500">
                                <TwitterShareButton
                                  url={window.location.href}
                                  quote={
                                    "AzamDeal offering you some best Deals"
                                  }
                                  hashtags={"#AzamDeal.."}
                                >
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                              </li>
                              <li className="flex items-center text-center border border-gray-100 rounded-full hover:bg-emerald-500  mr-2 transition ease-in-out duration-500">
                                <RedditShareButton
                                  url={window.location.href}
                                  quote={
                                    "AzamDeal offering you some best Deals"
                                  }
                                  hashtags={"#AzamDeal.."}
                                >
                                  <RedditIcon size={32} round />
                                </RedditShareButton>
                              </li>
                              <li className="flex items-center text-center border border-gray-100 rounded-full hover:bg-emerald-500  mr-2 transition ease-in-out duration-500">
                                <WhatsappShareButton
                                  url={window.location.href}
                                  title={"AZAMDEAL"}
                                >
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                              </li>
                              <li className="flex items-center text-center border border-gray-100 rounded-full hover:bg-emerald-500  mr-2 transition ease-in-out duration-500">
                                <LinkedinShareButton
                                  url={window.location.href}
                                  quote={
                                    "AzamDeal offering you some best Deals"
                                  }
                                  hashtags={"#AzamDeal.."}
                                >
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full xl:w-5/12 lg:w-6/12 md:w-5/12">
                                                <div className="mt-6 md:mt-0 lg:mt-0 bg-gray-50 border border-gray-100 p-4 lg:p-8 rounded-lg">
                                                    <ProductSideCard
                                                        bulletPoints={
                                                            getProductData && getProductData.product_desc
                                                        }
                                                    />
                                                </div>
                                            </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="p-4 md:p-5 lg:p-5 bg-white rounded-lg flex flex-col md:flex-col lg:flex-row ">
                  <div className="w-full md:w-full lg:w-2/3 border-r-0 md:border-r-0 lg:border-r-2">
                    <Review productid={getProductData.product_id} />
                  </div>
                  <div>
                    <YMAL productid={getProductData.product_id} />
                  </div>
                </div>
                <div>
                  {/*  */}
                  {getProductData && (
                    <RelatedProduct
                      onSelect={handleReltaedSelect}
                      CatID={getProductData.cat_id}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
      <Backdrop></Backdrop>
    </>
  );
};

export default OrderDetails;
