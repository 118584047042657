import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// import Link from "next/link";
// import Image from "next/image";
//internal import
// import OrderTable from "@components/order/OrderTable";
// import useUtilsFunction from "@hooks/useUtilsFunction";

const Invoice = () => {
  // console.log('invoice data',data)

  const location = useLocation();

  // Get the id from the state
  const { id, productId } = location.state || {}; // Access id from state

  //   const { getNumberTwo } = useUtilsFunction();
  const Navigate = useNavigate();

  const [getCustomerData, setGetCustomerData] = useState({});
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(true);
  const [getData, setGetData] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [getProductData, setGetProductData] = useState([]);

  const GetProductData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + `orderDetailsbyID/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // Assuming result is correctly logged and has the expected structure
        console.log("Full API Result:", result);

        if (Array.isArray(result) && result.length > 0) {
          let allData = [];
          result.forEach((res) => {
            if (res.data) {
              allData.push(res.data);
            }
          });

          allData = allData.flat();
          console.log("All Data Collected:", allData);

          // Now filter the combined data array by productId
          const filteredData = allData.filter(
            (item) => item.product_id === productId
          );
          console.log("Filtered Data:", filteredData);

          // Update state
          const FS = filteredData[0];

          console.log("FS Data:", FS);

          setGetProductData(FS);
        } else {
          console.error("API result is not an array or is empty");
        }
      })
      .catch((error) => console.error("Error fetching product data:", error));
  };

  const getAllAddress = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }addressByCustomerId/${localStorage.getItem("customer_id")}`,
        requestOptions
      );
      const result = await response.json();
      console.log("User Address: ", result.data);

      if (result.data.length > 0) {
        // Since it's an array, access the first address
        setUserAddress(result.data[0]);
      } else {
        console.log("No address found");
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoad(false);
  };

  const GetOrderHistory = async () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BASE_URL +
        `DetailByCustomerId/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setGetData(result.data[0]); // Ensure we're using the first object
        if (result.status === 200) {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetOrderHistory();
    getAllAddress();
    GetProductData();
    console.log("Order Data Invoice Page:  ", JSON.stringify(getData));
  }, []);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `customer_address/${localStorage.getItem("customer_id")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("Address Data Invoice", result);
        setGetCustomerData(result.data[0]);
        setContact(result.data[0]["contact"]);
        setName(result.data[0]["name"]);
        setEmail(result.data[0]["email"]);
        if (result.status === 200) {
          setLoad(false);
        } else {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <Layout>
      <div className=" p-5 ">
        <div className="bg-[#16b216] w-[80%] p-4 m-5 rounded-lg">
          <h3 className="text-black">
            Thank You <span className="text-white">{name},</span> Your order has
            been received!
          </h3>
        </div>

        <div className="bg-indigo-50 p-8 rounded-t-xl">
          <div className="flex lg:flex-row md:flex-row flex-col lg:items-center justify-between pb-4 border-b border-gray-50">
            <div>
              <h1 className="font-bold font-serif text-2xl uppercase">
                Invoice
              </h1>
              <h6 className="text-gray-700">
                Status :{" "}
                {getData && getData.order_status_name === "Order Placed" && (
                  <span className="text-blue-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "Order Accepted" && (
                  <span className="text-teal-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "Ready to Pickup" && (
                  <span className="text-yellow-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "Dispatched" && (
                  <span className="text-purple-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "In Transit" && (
                  <span className="text-cyan-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "Delivered" && (
                  <span className="text-emerald-500">
                    {getData.order_status_name}
                  </span>
                )}
                {getData && getData.order_status_name === "Cancelled" && (
                  <span className="text-red-500">
                    {getData.order_status_name}
                  </span>
                )}
              </h6>
            </div>
            <div className="lg:text-right text-left">
              <h2 className="text-lg font-serif font-semibold mt-4 lg:mt-0 md:mt-0">
                <Link href="/">
                  <img width={110} height={40} src="/azamlogo.jpg" alt="logo" />
                </Link>
              </h2>
              {/* <p className="text-sm text-gray-500">
                {name}
                <br />

              </p> */}
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-between pt-4">
            <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
              <span className="font-bold font-serif text-sm uppercase text-gray-600 block">
                Date
              </span>
              <span className="text-sm text-gray-500 block">
                {getData.created_at !== undefined && (
                  <span>
                    {dayjs(getData?.created_at).format("MMMM D, YYYY")}
                  </span>
                )}
              </span>
            </div>
            <div className="mb-3 md:mb-0 lg:mb-0 flex flex-col">
              <span className="font-bold font-serif text-sm uppercase text-gray-600 block">
                {/* Invoice No. */}
              </span>
              <span className="text-sm text-gray-500 block">
                {/* #{data?.invoice} */}
              </span>
            </div>
            <div className="flex flex-col lg:text-right text-left">
              <span className="font-bold font-serif text-sm uppercase text-gray-600 block">
                Invoice To.
              </span>
              <span className="text-sm text-gray-500 block">
                {name} <br />
                {email} <span className="ml-2">{contact}</span>
                <br />
                {userAddress?.address_line} <br />
                {userAddress?.city}, {userAddress?.state} <br />
                {userAddress?.country}, {userAddress?.pincode}
              </span>
            </div>
          </div>
        </div>
        <div className="s">
          <div className="overflow-hidden lg:overflow-visible px-8 my-10">
            <div className="-my-2 overflow-x-auto">
              <table className="table-auto min-w-full border border-gray-100 divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="text-xs bg-gray-100">
                    <th
                      scope="col"
                      className="font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider text-left"
                    >
                      Sr.
                    </th>
                    <th
                      scope="col"
                      className="font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider text-left"
                    >
                      Product Name
                    </th>
                    <th
                      scope="col"
                      className="font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider text-center"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider text-center"
                    >
                      Item Price
                    </th>

                    <th
                      scope="col"
                      className="font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider text-right"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody
                  onClick={async () => {
                    const formattedProductName = getProductData.product_name
                      .replace(/[\s\/]+/g, "-") // Replace spaces and slashes with a single hyphen
                      .replace(/--+/g, "-") // Replace multiple hyphens with a single hyphen
                      .replace(/[^a-zA-Z0-9-]/g, ""); // Remove any non-alphanumeric characters except hyphens

                    Navigate(`/Product/${formattedProductName}`, {
                      state: {
                        id: getProductData.id,
                        name: getProductData.product_name,
                      }, // Pass the id in state
                    });
                  }}
                  className="bg-white divide-y divide-gray-100 text-serif text-sm cursor-pointer"
                >
                  <tr>
                    <th className="px-6 py-1 whitespace-nowrap font-normal text-gray-500 text-left">
                      {1}
                    </th>
                    <td className="px-6 py-1 whitespace-nowrap font-normal text-gray-500">
                      {getProductData.product_name}
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap font-bold text-center">
                      {getProductData.item_qty}
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap font-bold text-center font-DejaVu">
                      {getProductData.item_price}
                    </td>
                    <td className="px-6 py-1 whitespace-nowrap text-right font-bold font-DejaVu k-grid text-red-500">
                      {getProductData.total_items_price}
                    </td>
                  </tr>
                </tbody>

                {/* <OrderTable data={data} currency={currency} /> */}
              </table>
            </div>
          </div>
        </div>

        <div className="border-t border-b border-gray-100 p-10 bg-emerald-50">
          <div className="flex lg:flex-row md:flex-row flex-col justify-between pt-4">
            <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
              <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 block">
                Payment Method
              </span>
              <span className="text-sm text-gray-500 font-semibold font-serif block">
                {getProductData?.payment_mode}
              </span>
            </div>
            <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
              <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 block">
                Shipping Cost
              </span>
              <span className="text-sm text-gray-500 font-semibold font-serif block">
                {/* {data.shippingCost} */}0
              </span>
            </div>
            <div className="mb-3 md:mb-0 lg:mb-0  flex flex-col sm:flex-wrap">
              <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 block">
                Discount
              </span>
              <span className="text-sm text-gray-500 font-semibold font-serif block">
                {getProductData.discount_amount
                  ? getProductData.discount_amount
                  : 0}
              </span>
            </div>
            <div className="flex flex-col sm:flex-wrap">
              <span className="mb-1 font-bold font-serif text-sm uppercase text-gray-600 block">
                Total Amount
              </span>
              <span className="text-2xl font-serif font-bold text-red-500 block">
                {getProductData.total_items_price}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Invoice;
