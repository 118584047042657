import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";

const fetchImageAsBlob = async (imageUrl) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return blob;
};

const compressBlobImage = async (blob) => {
  const options = {
    maxSizeMB: 1, 
    maxWidthOrHeight: 1920, 
    useWebWorker: true, 
  };
  const compressedFile = await imageCompression(blob, options);
  return compressedFile;
};

const FooterTop = () => {
  const[compressedImage,setCompressedImage] = useState();

  useEffect(() => {
    let blobUrl = null;
    const imageCompressor = async (imagePath) => {
      const blob = await fetchImageAsBlob(imagePath);
      const compressedFile = await compressBlobImage(blob);
      blobUrl = URL.createObjectURL(compressedFile);
      setCompressedImage(blobUrl);
    }
    imageCompressor('/footerRight.png');
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [])

  return (
    <div>
      <div
        id="downloadApp"
        className="bg-indigo-50 py-10 lg:py-5 bg-repeat bg-center overflow-hidden"
      >
        <div className="max-w-screen-2xl mx-auto px-4 sm:px-10">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2 md:gap-3 lg:gap-3 items-center">
            <div className="flex-grow hidden lg:flex md:flex md:justify-items-center lg:justify-start">
              <img
                src={compressedImage}
                alt="app download"
                width={600}
                height={450}
                className="block w-auto"
              />
            </div>
            <div className="text-center">
              <h3 className="text-xl md:text-2xl lg:text-3xl font-bold font-serif mb-3">
                Get the most Authentic Herbs From AZAMDEAL Store
              </h3>
              <p className="text-base opacity-90 leading-7">
                AZAMDEAL follows a natural Indian process of drying herbs under
                sunlight.
              </p>
              <div className="mt-8 flex justify-center">
                <Link to="/">
                  <p className="mx-2 mt-6" target="_blank" rel="noreferrer">
                    <img
                      width={170}
                      height={50}
                      className="mr-2 rounded"
                      src="/app/app-store.svg"
                      alt="app store"
                    />
                  </p>
                </Link>
                <div className="ml-2">
                  <p target="_blank" rel="noreferrer">
                    <a href="https://play.google.com/store/apps/details?id=com.azamdeal&hl=en">
                      <img
                        width={170}
                        height={50}
                        className="rounded mt-6"
                        src="/app/play-store.svg"
                        alt="play store"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="md:hidden lg:block">
              <div className="flex-grow hidden lg:flex md:flex lg:justify-end">
                <img
                  src="/footerLeft.png"
                  width={500}
                  height={394}
                  alt="app download"
                  className="block w-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTop;
