import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import InputArea from "../Pages/Form/InputArea";
import Dashboard from "./Dashboard";

const UpdatePassword = () => {
  let Navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Error Hooks
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passNotMatchError, SetPassNotMatchError] = useState("");
  const [wrongCurrentMessage,serWrongCurrentMessage] = useState("");
  const [passwordValidationError,setPasswordValidationError] = useState("");


  // Load Hooks
  const [load, setLoad] = useState(false);

  const validatePassword = (password) => {
    const minLength = 8; // Minimum length for password
    const hasUpperCase = /[A-Z]/.test(password); // Check for uppercase letter
    const hasLowerCase = /[a-z]/.test(password); // Check for lowercase letter
    const hasNumber = /[0-9]/.test(password);    // Check for digit
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Check for special character
  
    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return ""; // Return empty string if the password is valid
  };
  

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoad(true);
    setCurrentPasswordError("");
    setConfirmPasswordError("");
    setNewPasswordError("");
    SetPassNotMatchError("");
    let msg = "this fild is required";
    if (currentPassword === "") {
      setCurrentPasswordError(msg);
      setLoad(false);
    } else if (newPassword === "") {
      setNewPasswordError(msg);
      setLoad(false);
    } else if (confirmPassword === "") {
      setConfirmPasswordError(msg);
      setLoad(false);
    } else if (validatePassword(newPassword) !== "") {
      setPasswordValidationError(validatePassword(newPassword));
      setLoad(false);

    } else if (newPassword !== confirmPassword) {
      setPasswordValidationError("");
      SetPassNotMatchError("password does not match");
      setLoad(false);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        c_id: localStorage.getItem("customer_id"),
        password: currentPassword,
        new_pass: confirmPassword,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "updatepassword",
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status === 200) {
            toast.success("Passwords updated successfully", {
              theme: "light",
              autoClose: "2000",
            });
            setLoad(false);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            Navigate("/dashboard");
          } else {
            setLoad(false);
            serWrongCurrentMessage(`current ${result.message}`);
          }
        })
        .catch((error) => {
          console.log("error ==>", error.message); 
        }
      );
    }
  };

  return (
    <div>
      <Dashboard
        title="Update-Password"
        description="This is password update page"
      >
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h2 className="text-xl font-sans font-bold mb-5">
                  Change Password
                </h2>
              </div>
            </div>
          </div>
          {/*  */}
          <hr className="border border-emerald-500" />

          <div className="mx-20 mt-10">
            <InputArea
              className="mt-2"
              label="Enter Current Password"
              name="password"
              type="password"
              placeholder="current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            {currentPasswordError && (
              <div className="text-xs font-semibold text-red-500 ml-1">
                {currentPasswordError}
              </div>
            )}
            {wrongCurrentMessage && (
              <div className="text-xs font-semibold text-red-500 ml-1">
                {wrongCurrentMessage}
              </div>
            )}
          </div>
          <div className="grid grid-cols-6 gap-6 mt-5 mx-20">
            <div className="col-span-6 sm:col-span-3">
              <InputArea
                label="Password"
                name="name"
                type="password"
                placeholder="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {newPasswordError && (
                <div className="text-xs font-semibold text-red-500 ml-1">
                  {newPasswordError}
                </div>
              )}
              {!passwordValidationError && passNotMatchError && (
                <div className="text-xs font-semibold text-red-500 ml-1">
                  {passNotMatchError}
                </div>
              )}
              {passwordValidationError && (
                <div className="text-xs font-semibold text-red-500 ml-1">
                  {passwordValidationError}
                </div>
              )}
            </div>

            <div className="col-span-6 sm:col-span-3">
              <InputArea
                label="Confirm Password"
                name="phone"
                type="password"
                placeholder="confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmPasswordError && (
                <div className="text-xs font-semibold text-red-500 ml-1">
                  {confirmPasswordError}
                </div>
              )}
              {passNotMatchError && (
                <div className="text-xs font-semibold text-red-500 ml-1">
                  {passNotMatchError}
                </div>
              )}
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3 mt-5 text-right">
            <button
              onClick={handleChangePassword}
              type="submit"
              className="md:text-sm leading-5 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none bg-emerald-500 text-white px-5 md:px-6 lg:px-8 py-2 md:py-3 lg:py-3 hover:text-white hover:bg-emerald-600 h-12 mt-1 text-sm lg:text-sm w-full sm:w-auto"
            >
              Change Password
            </button>
          </div>
        </div>
      </Dashboard>
      <ToastContainer />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
      >
        <CircularProgress color="success" />
      </Backdrop>
    </div>
  );
};

export default UpdatePassword;
